import React from 'react';
// import Navbar from '../components/Navbar';
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {faGithub, faLinkedin, faHackerrank, faTwitter, faInstagram} from '@fortawesome/free-brands-svg-icons';
// import { Link } from 'gatsby';

function contact() {
  return (
    <>
    {/* <Navbar />
    <main className="h-screen w-full p-6 bg-slate-100 top-30 overflow-hidden">
      <div className="w-full h-full mx:auto flex flex-col relative top-10 justify-start items-center">
        <span className="text-4xl font-semibold m-1 ">
          Get In Touch
        </span>
        <span className="text-base text-center p-1/3 my-4 font-normal ">
        Although I’m not currently looking for any new opportunities, my inbox is always open. Whether you have a question or just want to say hi, I’ll try my best to get back to you!
        </span>
        <button className="text-lg m-t-30 border-2 border-green-500 m-6 w-1/3 h-14 text-green-600 hover:bg-green-500 hover:text-white duration-300 font-medium rounded-md">
        <Link to='mailto:harshitkumar2503@gmail.com' className='cursor-pointer'>Say Hello</Link>
        </button>
        <div className='flex flex-row justify-around items-center m-2 p-2'>
            <Link to='https://github.com/HarshitChauhan/' className='cursor-pointer h-20 w-20 p-1'><FontAwesomeIcon icon={faGithub}/></Link>
            <Link to='http://linkedin.com/in.harshitkumar2503/' className='cursor-pointer h-20 w-20 p-1'><FontAwesomeIcon icon={faLinkedin}/></Link>
            <Link to='https://hackerrank.com/harshitkumar2503/' className='cursor-pointer h-20 w-20 p-1'><FontAwesomeIcon icon={faHackerrank}/></Link>
            <Link to='https://twitter.com/HarshitKChauhan/' className='cursor-pointer h-20 w-20 p-1'><FontAwesomeIcon icon={faTwitter}/></Link>
            <Link to='https://instagram.com/harshitkumarchauhan/' className='cursor-pointer h-20 w-20 p-1'><FontAwesomeIcon icon={faInstagram}/></Link>
        </div>
        <span className='text-thin text-center text-gray-500 fixed bottom-20'>
        Designed & Built by Harshit Kumar
        </span>
      </div>
    </main> */}
    </>
  )
}

export default contact